<template>
  <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_profile_details"
      aria-expanded="true"
      aria-controls="kt_account_profile_details"
    >
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Profil Bilgileri</h3>
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->

    <!--begin::Content-->
    
    <div id="kt_account_profile_details" class="collapse show" v-if="profileSettings">
      
      <!--begin::Form-->
      <Form id="kt_account_profile_details_form" class="form" @submit="saveChanges1()">
        <!--begin::Card body-->
        <div class="card-body border-top p-9">

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label required fw-bold fs-6">Adınız Soyadız</label>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-lg-6 fv-row">
                  <input type="text" name="name" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Adınız" v-model="profileSettings.name" />
                </div>

                <div class="col-lg-6 fv-row">
                  <input type="text" name="surname" class="form-control form-control-lg form-control-solid" placeholder="Soyadınız" v-model="profileSettings.surname" />
                </div>
              </div>
            </div>
          </div>


          <div class="row mb-6">
            <label class="col-lg-4 col-form-label required fw-bold fs-6">E-posta adresiniz</label>
            <div class="col-lg-8 fv-row">
              <input type="text" name="email" class="form-control form-control-lg form-control-solid" placeholder="E-posta adresiniz" v-model="profileSettings.email"  />
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              <span class="required">Telefon Numaranız / Doğum Tarihiniz</span>
            </label>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-lg-6 fv-row">
                  <input type="tel" name="phone" class="form-control form-control-lg form-control-solid" placeholder="Telefon Numaranız"  v-model="profileSettings.phone" />
              </div>
              <div class="col-lg-6 fv-row">
                <input type="date" name="birthday" class="form-control form-control-lg form-control-solid" placeholder="Doğum Tarihiniz"  v-model="profileSettings.birthday" />
              </div>
            </div>
          </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">Cinsiyet</label>
            <div class="col-lg-8 fv-row">
              <div class="d-flex align-items-center mt-3">
                <label class="form-check form-check-inline form-check-solid me-5">
                  <input class="form-check-input" name="gender" :checked="profileSettings.gender == 'M'" type="radio" v-model="profileSettings.gender" />
                  <span class="fw-bold ps-2 fs-6"> Erkek </span>
                </label>

                <label class="form-check form-check-inline form-check-solid">
                  <input class="form-check-input" name="gender" type="radio" :checked="profileSettings.gender == 'F'" v-model="profileSettings.gender" />
                  <span class="fw-bold ps-2 fs-6"> Kadın </span>
                </label>
              </div>
            </div>
          </div>
          
        </div>
        <!--end::Card body-->

        <!--begin::Actions-->
        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <button type="reset" class="btn btn-white btn-active-light-primary me-2">Vazgeç</button>

          <button type="submit" id="kt_account_profile_details_submit" ref="submitButton1" @click.prevent="updateProfile()" class="btn btn-primary">
            <span class="indicator-label"> Kaydet </span>
            <span class="indicator-progress">
              Lütfen bekleyin...
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
        </div>
        <!--end::Actions-->
      </Form>
      <!--end::Form-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->

  <!--begin::Sign-in Method-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_signin_method"
    >
      <div class="card-title m-0">
        <h3 class="fw-boldest m-0">Şifre değiştirme</h3>
      </div>
    </div>
    <!--end::Card header-->

    <!--begin::Content-->
    <div id="kt_account_signin_method" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body border-top p-9">

        <!--begin::Password-->
        <div class="d-flex flex-wrap align-items-center mb-8">

          <div id="kt_signin_password_edit" class="flex-row-fluid">
            <div class="fs-6 fw-bold text-gray-600 mb-4">
              Şifreniz en az 8 karakter uzunluğunda olmalıdır.
            </div>

			
            <!--begin::Form-->
            <Form id="kt_signin_change_password" class="form" novalidate="novalidate">
              <div class="row mb-6">

                <div class="col-lg-4">
                  <div class="fv-row mb-0">
                    <label for="currentpassword" class="form-label fs-6 fw-bolder mb-3" >Mevcut Şifreniz</label>
                    <div class="position-relative mb-3">
                      <input type="password" class="form-control form-control-lg form-control-solid fw-bold fs-6" name="currentpassword" id="currentpassword" v-model="passwordForm.oldpass" />
                      <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 passwordeyebtn" data-field="currentpassword">
                        <i class="bi bi-eye-slash fs-2"></i>
                        <i class="bi bi-eye fs-2" style="display:none"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="fv-row mb-0">
                    <label for="newpassword" class="form-label fs-6 fw-bolder mb-3" >Yeni Şifreniz</label>
                    <div class="position-relative mb-3">
                      <input type="password" class="form-control form-control-lg form-control-solid fw-bold fs-6" name="newpassword" id="newpassword" v-model="passwordForm.newPass" />
                       <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 passwordeyebtn" data-field="newpassword">
                        <i class="bi bi-eye-slash fs-2"></i>
                        <i class="bi bi-eye fs-2" style="display:none"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="fv-row mb-0">
                    <label for="confirmpassword" class="form-label fs-6 fw-bolder mb-3" >Yeni şifrenizi tekrar girin</label>
                    <div class="position-relative mb-3">
                      <input type="password" class="form-control form-control-lg form-control-solid fw-bold fs-6" name="confirmpassword" id="confirmpassword" v-model="passwordForm.newPassRepeat" />
                      <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 passwordeyebtn" data-field="confirmpassword">
                        <i class="bi bi-eye-slash fs-2"></i>
                        <i class="bi bi-eye fs-2" style="display:none"></i>
                      </span>
                    </div>
                  </div>
                </div>

              </div>
              <div class="d-flex">
                <button id="kt_password_submit" type="submit" ref="updatePasswordButton" @click.prevent="updatePassword()" class="btn btn-primary me-2 px-6">
                  <span class="indicator-label"> Şifremi güncelle </span>
                  <span class="indicator-progress">
                    Lütfen bekleyin...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>

                <button id="kt_password_cancel" type="button" class="btn btn-color-gray-400 btn-active-light-primary px-6">
                  Vazgeç
                </button>

              </div>
            </Form>
            <!--end::Form-->
          </div>         
        </div>
        <!--end::Password-->
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Sign-in Method-->


</template>



<script>
import api from '@/core/services/ApiService'
import Swal from "sweetalert2/dist/sweetalert2.js";
import JwtService from '@/core/services/JwtService';

export default  {
  name:'AccountSettings',
  data(){
    return{
      profileSettings:{
        name:null,
        surname:null,
        birthday:null,
        email:null,
        phone:null,
        gender:null
      },

      passwordForm:{
        oldPass:null,
        newPass:null,
        newPassRepeat:null,
        userId:62
      }
    }
  },

  mounted(){
    let user = JSON.parse(JwtService.getUser());
     api.get('/User/GetUser/'+user.userId).then((res)=>{
      this.profileSettings = res.data;      
    });
   
  },
  methods:{
    updateProfile () {
      const submitButton1 = this.$refs.submitButton1;
      
      submitButton1.setAttribute("data-kt-indicator", "on");
      api.post('/User/UpdateUser',this.profileSettings).then((res)=>{
          submitButton1.removeAttribute("data-kt-indicator");
          Swal.fire({
            text: "Kullanıcı bilgileri güncellendi",
            icon: "success",
            confirmButtonText: "Tamam",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          });
      }).catch((err)=>{
          Swal.fire({
            text: "Bir hata oluştu",
            icon: "error",
            confirmButtonText: "Tamam",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-danger",
            },
          });
      });
      
    },

    updatePassword() {

      const updatePasswordButton = this.$refs.updatePasswordButton;
      updatePasswordButton.setAttribute("data-kt-indicator", "on");
      
      api.post('/User/ChangePassword',this.passwordForm).then((res)=>{
        updatePasswordButton.removeAttribute("data-kt-indicator");
        Swal.fire({
            text: "Şifreniz güncellendi",
            icon: "success",
            confirmButtonText: "Tamam",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          });
      }).catch((err)=>{
        updatePasswordButton.removeAttribute("data-kt-indicator");
         Swal.fire({
            text: 'Hatalı şifre formatı',
            icon: "error",
            confirmButtonText: "Tamam",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          });
      })
    }
  }

}

</script>
<style scoped>
.passwordeyebtn{display: none;}
</style>